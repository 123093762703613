import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../subcomponents/navbar";
import { Footer } from "../subcomponents/footer";
import Axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

import heroimg from "../utils/hero-bg.jpg";

export const EachSection = () => {
  const [pageData, setPageData] = useState([]);
  const [tobepageData, setToBePageData] = useState([]);

  const params = useParams();
  const [blogData, setblogdata] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(6);
  const [loading, setLoading] = useState(false);

  console.log(pageData);

  const getAllPosts = async () => {
    try {
      setLoading(true);
      const data = await Axios.get(
        "https://blogbackend2.onrender.com/allposts"
      );
      console.log(data);
      const filtered = data.data.reverse().filter((item) => {
        return item.category.toUpperCase() === params.name.toUpperCase();
      });
      console.log(filtered);
      setPageData(filtered);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(pageData);

  useEffect(() => {
    getAllPosts();
  }, [params.name]);
  const getPageData = () => {
    if (pageData.length > tobepageData.length) {
      console.log(start);
      setToBePageData([...tobepageData, ...pageData.slice(start, end)]);
    }
    console.log(tobepageData);
  };
  useEffect(() => {
    setToBePageData([]);

    console.log("params changed");

    setStart(0);
    setEnd(6);
  }, [params.name]);

  useEffect(() => {
    getPageData();
    console.log(pageData);
    console.log("Changed");
  }, [pageData]);
  useEffect(() => {
    getPageData();
  }, [end]);

  const navigate = useNavigate();

  return loading ? (
    <div className="flex justify-center items-center h-screen bg-black">
      <div class="patterns">
        <svg width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots)">
            {" "}
          </rect>

          <text x="50%" y="50%" text-anchor="middle">
            Blogger
          </text>
        </svg>
      </div>
    </div>
  ) : (
    tobepageData.length > 0 && (
      <div className="bg-black">
        <Navbar></Navbar>

        <div className="mt-5 text-center uppercase text-white">
          <h5 className="text-xl md:text-3xl ">
            {params.name} <span className="text-orange-500">Section</span>
          </h5>
          <div className="mt-5 ">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center">
              {tobepageData.map((item) => {
                return (
                  <div className="w-[300px]  tobeincreasedonlg sm:w-[290px] mx-auto md:w-[350px] lg:w-[300px] mb-[60px] flex flex-col items-center justify-center md:justify-start md:items-start ">
                    <img
                      src={item.image}
                      alt=""
                      className="hover:scale-105 transition-transform  rounded-xl w-[300px] tobeincreasedonlg sm:w-[290px] md:w-[350px] lg:w-[300px] h-[250px]  object-cover"
                    />
                    <div className="mt-3 md:mt-6  w-[30%] ">
                      <span
                        onClick={() => {
                          // window.open(`${item.category}`, "_blank");
                          navigate(`${item.category}`);
                        }}
                        className="cursor-pointer bg-gray-700 rounded-xl  text-sm py-1 px-2 text-white"
                      >
                        {item.category}
                      </span>
                    </div>
                    <div
                      className="my-3 text-[white] cursor-pointer hover:text-orange-500 hover:underline text-2xl mx-auto md:mx-0 text-center-on-sm"
                      onClick={() => {
                        // window.open(`/post/${item._id}`, "_blank");
                        navigate(`/post/${item._id}`);
                      }}
                    >
                      {item.title.split(" ").slice(0, 10).join(" ")}...
                    </div>
                    <div className="mt-2 text-base text-white text-center-on-sm addmlonlg">
                      {item.content.split(" ").slice(0, 30).join(" ")}...
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="mt-2">
          {pageData.length > tobepageData.length && (
            <h3
              onClick={() => {
                setStart(start + 6);

                setEnd(end + 6);
              }}
              className="text-center text-2xl text-white my-5 font-bold cursor-pointer"
            >
              Load More
            </h3>
          )}
        </div>
        <Footer></Footer>
      </div>
    )
  );
};
